import React, { useState, useEffect, useRef } from 'react';
import { RadioGroup, FormControlLabel, Radio, TextField, Button, Container, Typography, Box, InputAdornment, CircularProgress, FormControl, InputLabel, Select, MenuItem, Paper } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import Joyride from 'react-joyride';

function App() {
  const apiHost = 'https://doc-ai-bi.apextechinc.com';
  //const apiHost = 'http://127.0.0.1:4008';

  const tourSteps = [
    {
      target: '#main-title', // Use the class name of your messages list
      content: (
        <div>
          <h3>Welcome to the Apex Chat Bot!</h3>
          <p>Go through the tour to check features, no more SSL issues! </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: '#documents',
      content: 'At first configure the document source. We support 2 types of documents: single file and Google Drive folder.',
    },
    {
      target: '#fileTypeDoc',
      content: (
        <div>
          <p>Single File - is when you have only 1 file and have a link to this file which downloads it.</p>
          <p>Provide the file url which will be used to download the file. It should be public accessible. To check if it is a corret link - go to the incognito mode in browser and try to open it, the file should be downloaded automatically.</p>
        </div>
      ),
    },
    {
      target: '#fileTypeSelect',
      content: 'Select the file type if you are using Single File. We support only txt and pdf files.',
    },
    {
      target: '#gfolderTypeDoc',
      content: (
        <div>
          <p>Google Drive Folder Type - is when you have a folder in Google Drive with multiple files and you want to search in all of them.</p>
          <p>Provide ID of the Google Drive folder which you can get from the URL in browser. </p>
          <p>For example, you have the Google Drive folder with URL:</p>
          <p style={{ lineBreak: 'anywhere'}}>https://drive.google.com/drive/folders/<b>1NAqe85ZJxdYnhEjXuKVfSdfMFycO7nJW</b></p>
          <p>where</p>
          <p style={{ lineBreak: 'anywhere'}}><b>1NAqe85ZJxdYnhEjXuKVfSdfMFycO7nJW</b></p>
          <p>is the ID of the folder</p>
        </div>
      ),
    },
    {
      target: '#message-input',
      content: 'Enter your question here. You can use any language you want.',
    },
    {
      target: '#send-button',
      content: 'And click this button to send your message.',
      placement: 'top', // Show above the send button
    },
    {
      target: '#clearCacheBtn',
      content: 'Click it if you have changed the document or documents inside the Google Drive folder',
      placement: 'top'
    }
  ];

  const questionStyle = {
    padding: '0.5rem',
  };

  const asnwerStyle = {
    padding: '0.5rem',
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
  };

  const [linkInput, setLinkInput] = useState('https://docs.google.com/document/d/1L84aIqBrgckwkodwTZ2FUPvxTGrOP03Y5kdNNLkh3ss/export?format=txt');
  const [googleFolderId, setGoolgeFolderId] = useState('1NAqe85ZJxdYnhEjXuKVfSdfMFycO7nJW');
  const [fileType, setFileType] = useState('txt');
  const [questionInput, setQuestionInput] = useState('Якщо я відгуляв в цьому році 4 відпускних днів, то скільки мені залишилось?');
  const [answers, setAnswers] = useState([]);
  const [fileUrlError, setFileUrlError] = useState('');
  const [questionError, setQuestionError] = useState('');
  const [folderIdError, setFolderIdError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isTourRunning, setIsTourRunning] = useState(localStorage.getItem('tourCompleted') !== 'true');
  const [isFileSettingsOpen, setIsFileSettingsOpen] = useState(isTourRunning);
  const [docsType, setDocsType] = useState('file');
  const messagesEndRef = useRef(null);

  const handleDocTypeChange = (event) => {
    setDocsType(event.target.value);
  };

  const validateInputs = () => {
    let isValid = true;
    if (docsType === 'file' && !linkInput.trim()) {
      setFileUrlError('File URL is required.');
      setIsFileSettingsOpen(true);
      isValid = false;
    } else {
      setFileUrlError('');
    }

    if (docsType === 'gfolder' && !googleFolderId.trim()) {
      setFolderIdError('Folder Id is required.');
      setIsFileSettingsOpen(true);
      isValid = false;
    } else {
      setFolderIdError('');
    }

    if (!questionInput.trim()) {
      setQuestionError('Question is required.');
      isValid = false;
    } else {
      setQuestionError('');
    }

    return isValid;
  };

  const handleQuestion = () => {
    if (!validateInputs()) {
      return;
    }
    setLoading(true);

    // Sending the answer input in JSON format to `https://answer` endpoint with a POST request
    const question = questionInput.toString();
    axios
      .post(`${apiHost}/${docsType === 'file' ? 'file' : 'gfolder'}`, {
        fileUrl: linkInput,
        folderId: googleFolderId,
        question: questionInput,
        fileType,
      })
      .then((response) => {
        console.log(response);
        setQuestionInput('');
        setAnswers([...answers, {
          question,
          index: answers.length + 1,
          answer: response.data.answer,
        }]);
        setLoading(false);
      })
      .catch((error) => {
        window.alert(JSON.stringify(error));
        setLoading(false);
      });
  };

  const handleClearCache = () => {
    axios
      .post(`${apiHost}/clear`, {
        file: docsType === 'file' ? linkInput : googleFolderId,
      })
      .then((response) => {
        window.alert('Cache has been purged');
      })
      .catch((error) => {
        window.alert(JSON.stringify(error));
      });
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleQuestion();
    }
  }

  useEffect(() => {
    // Scroll to the bottom when a new message is added
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [answers]);

  const startJoyride = () => {
    setIsTourRunning(true);
    setIsFileSettingsOpen(true);
  };

  const toggleFileSettings = () => {
    setIsFileSettingsOpen(!isFileSettingsOpen);
  }

  return (
    <div>
      <Container maxWidth="md">
        <Box my={4} textAlign="center">
          <Typography variant="h4" id="main-title">Apex Chat Bot (powered by Chat GPT)</Typography>
          <Button onClick={startJoyride}>Show tour</Button>
        </Box>
      </Container>
      <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 143px)', justifyContent: 'flex-end' }}>
        <Container maxWidth="md" style={{ padding: '1rem', overflowY: 'auto' }}>
          {answers.length > 0 ? (
            <Box my={2}>
              {answers.sort((a, b) => a.index - b.index).map((pair) => (
                <div key={pair.index}>
                  <Typography style={questionStyle}>{pair.question}</Typography>
                  <Typography style={asnwerStyle}>{pair.answer}</Typography>
                </div>
              ))}
            </Box> ) : null }
            <div ref={messagesEndRef} />
        </Container>
        <Paper elevation={3}>
          <Container maxWidth="md">
            <Box my={1}>
              <TextField
                fullWidth
                id="message-input"
                value={questionInput}
                onChange={(e) => setQuestionInput(e.target.value)}
                label="Enter your question"
                variant="outlined"
                multiline
                rows={4}
                size="small"
                margin="dense"
                error={!!questionError}
                onKeyDown={handleInputKeyDown}
                disabled={loading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        id="send-button"
                        onClick={handleQuestion}
                        startIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
                        disabled={loading}
                      >
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <Button onClick={toggleFileSettings}>{isFileSettingsOpen ? 'Hide File Settings' : 'Show File Settings'}</Button>
            </Box>
           {isFileSettingsOpen && (
              <div>
                <Box my={1} id="documents">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5">Document</Typography>
                  <Button onClick={handleClearCache} style={{marginLeft: '20px'}} id="clearCacheBtn">Clear Cache</Button>
                </div>
                <RadioGroup row aria-label="options" name="options" value={docsType} onChange={handleDocTypeChange}>
                  <FormControlLabel value="file" control={<Radio />} label="File" id="fileTypeDoc"/>
                  <FormControlLabel value="gfolder" control={<Radio />} label="Google Drive Folder" id="gfolderTypeDoc" />
                </RadioGroup>
                <Typography>Should be public accessible</Typography>
                {docsType === 'file' ? (<TextField
                  fullWidth
                  value={linkInput}
                  onChange={(e) => setLinkInput(e.target.value)}
                  label="Enter HTTP link"
                  variant="outlined"
                  size="small"
                  placeholder="https://docs.google.com/document/d/1L84aIqBrgckwkodwTZ2FUPvxTGrOP03Y5kdNNLkh3ss/export?format=txt"
                  margin="dense"
                  error={!!fileUrlError}
                  id="fileUrlInput"
                />) : null}
                {docsType === 'gfolder' ? (<TextField
                  fullWidth
                  value={googleFolderId}
                  onChange={(e) => setGoolgeFolderId(e.target.value)}
                  label="Enter folder ID"
                  variant="outlined"
                  size="small"
                  placeholder="1NAqe85ZJxdYnhEjXuKVfSdfMFycO7nJW"
                  margin="dense"
                  error={!!folderIdError}
                  id="folderIdInput"
                />) : null}
              </Box>

              { docsType === 'file' ? (
              <Box my={1}>
                {/* File Type dropdown */}
                <FormControl fullWidth variant="outlined" size="small" margin="dense">
                  <InputLabel id="file-type-label">File Type</InputLabel>
                  <Select
                    labelId="file-type-label"
                    value={fileType}
                    onChange={(e) => setFileType(e.target.value)}
                    label="File Type"
                    id="fileTypeSelect"
                  >
                    <MenuItem value="txt">Text (txt)</MenuItem>
                    <MenuItem value="pdf">PDF (pdf)</MenuItem>
                  </Select>
                </FormControl>
              </Box>) : null}
            </div>)}
          </Container>
          <Box my={1} textAlign="center">
            <div>Copyright © {new Date().getFullYear()} <a href="https://apextechinc.com" target='blank'>ApexTech, Inc</a>.</div>
          </Box>
        </Paper>
      </div>
      <Joyride
        steps={tourSteps}
        continuous
        showProgress
        run={isTourRunning}
        callback={({ type }) => {
          if (['tour:end'].includes(type)) {
            setIsTourRunning(false); // End the tour after it's finished or skipped
            localStorage.setItem('tourCompleted', 'true');
            setIsFileSettingsOpen(false);
          }
        }}
      />
    </div>
  );
}

export default App;
